import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { AppState } from '../+state/app/app.state';
import { getNetworkStatus, getUser } from '../+state/app/app.selectors';
import { AppActions } from '../+state/app/app.actions';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from 'src/environments/environment';
import {SentryErrorHandler} from "./sentry-error-handler";
import {FileService} from "../+state/file/file.service";
import {draw} from "../helpers";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private token: string | undefined;
  private networkAvailability = false;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store<AppState>,
    private fileService: FileService,
    private sentryErrorHandler: SentryErrorHandler,
    ) {
    this.store.select(getUser).pipe().subscribe(user => {
      if (user) {
        this.token = user.token;
      }
    });

    this.store.select(getNetworkStatus).pipe().subscribe(network => {
      this.networkAvailability = network.available;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = request.headers.set('ngsw-bypass', 'true');

    if (this.token) {
      headers = headers.set(environment.TOKEN_KEY, `${this.token}`);
    }

    const modifiedRequest = request.clone({ headers });

    return next.handle(modifiedRequest).pipe(
      tap(async (response: any) => {
        if (!this.networkAvailability && response && response.status === 200) {
          this.store.dispatch(AppActions.SET_NETWORK_STATUS({ available: true }));
          await draw();
          this.fileService.processQueueEvent.next(undefined);
        }
      }),
      catchError((error: HttpErrorResponse) => {

        // ignore 401 errors on logging in/out
        const isAccountsUrl = request.url.startsWith(`${environment.API}/common/accounts`);

        if (error.status === 401) {
          if (!isAccountsUrl) {
            if (this.router.url !== '/login') {
              this.router.navigateByUrl('/login');
            }
          } else {
            // consume error message
          }
        } else if (
          error.status === 0 ||
          error.status === 504
        ) {
          this.store.dispatch(AppActions.SET_NETWORK_STATUS({available: false}))
        }
        else if (
          error.status === 502
        ) {
          setTimeout(() => {
            const snackBar = this.snackBar.open(
              `Server is unavailable, please try again later.`,
              `\u00D7`,
              {
                duration: 5000,
                panelClass: ['app-notification-error']
              },
            );
          }, 0);
        } else {
          this.sentryErrorHandler.handleError(error);
        }
        return throwError(error);
      })
    );
  }
}
