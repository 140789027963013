import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { ImageInfo } from "../../constants";
import { Store } from "@ngrx/store";
import { FileState } from "./file.state";
import { uploadQueue } from "./file.selectors";
import { firstValueFrom, race, Subject, timer } from "rxjs";
import { FileActions } from "./file.actions";
import { StorageDriver } from './drivers/storage-driver';
import { NavigatorStorageDriver } from './drivers/navigator-storage-driver';
import { PhoneStorageDriver } from './drivers/phone-storage-driver';
import * as Sentry from "@sentry/angular-ivy";
import {IndexableType} from "dexie";
import {getNetworkStatus, getUser} from "../app/app.selectors";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {AppState} from "../app/app.state";
import {SentryErrorHandler} from "../../auth/sentry-error-handler";
import {MatDialog} from "@angular/material/dialog";
import {ItemNumberModalComponent} from "../../item-number-modal/item-number-modal.component";
import {Actions, MessageDialogComponent} from "../../message-dialog/message-dialog.component";

const PROCESS_LOOP = 1000;

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class FileService implements StorageDriver {
  private storageDriver: StorageDriver | any;

  token: any = null;
  user: any = {};

  processQueueEvent = new Subject<void>();
  uploadQueue: string[] | undefined;

  networkAvailability: boolean = false;

  constructor(
    private http: HttpClient,
    private store: Store<FileState>,
    private appStore: Store<AppState>,
    private sentryErrorHandler: SentryErrorHandler,
    private dialog: MatDialog,
  ) {
    this.initStorageDriver();

    this.appStore.select(getNetworkStatus).pipe().subscribe(network => {
      this.networkAvailability = network.available;
    });

    this.store.select(uploadQueue).subscribe((uploadQueue) => this.uploadQueue = uploadQueue);
    this.appStore.select(getUser).pipe(untilDestroyed(this)).subscribe(user => {
      this.user = user;
    });
    this.processQueueLoop();
  }

  private async initStorageDriver() {
    try {
      // Attempt to use PhoneStorageDriver first
      const phoneStorageDriver = new PhoneStorageDriver(this.sentryErrorHandler);
      await phoneStorageDriver.testAvailability();
      this.storageDriver = phoneStorageDriver;
      console.log('Using PhoneStorageDriver');
    } catch {
      console.warn('PhoneStorageDriver not available, falling back to NavigatorStorageDriver');
      // Fall back to NavigatorStorageDriver if PhoneStorageDriver is not available
      if (navigator.storage) { // todo: test getDirectory, getFileHandle & createWritable.
        this.storageDriver = new NavigatorStorageDriver(this.sentryErrorHandler);
        console.log('Using NavigatorStorageDriver');
      } else {
        // this.dialog.open(MessageDialogComponent, {
        //   data: {
        //     title: 'Critical Error',
        //     message: 'File system could not be accessed, files will not save.',
        //     buttons: [{
        //       label: 'Ok',
        //       type: Actions.OK,
        //       style: 'primary',
        //     },
        //       {
        //         label: 'Cancel',
        //         type: Actions.CANCEL,
        //         style: 'secondary',
        //       }],
        //   },
        // });
        console.error('No suitable storage driver available');
        throw new Error('No suitable storage driver available');
      }
    }
  }

  async processQueueLoop() {
    while (1) {
      try {
        await this.processQueue();
        await firstValueFrom(race(this.processQueueEvent, timer(PROCESS_LOOP)));
      } catch (e) {
        console.error(e);
      }
    }
  }

  async processQueue() {
    if (this.user && this.uploadQueue && this.uploadQueue.length > 0) {
      for (const fileKey of this.uploadQueue) {
        if (!this.networkAvailability) {
          break;
        }

        const file = await this.storageDriver.getFile(fileKey);
        const fileOwner = fileKey ? fileKey.split('/')[0] : ''; // file owner derived from first path of file key
        const isFileOwnedByLoggedInUser = this.user && (this.user.username === fileOwner);

        if (
          this.user &&
          this.user.username &&
          isFileOwnedByLoggedInUser &&
          file &&
          file.name &&
          file.path) {
          const username = this.user.username;

          try {
            const results = await firstValueFrom(this.uploadPhoto(
              file.path,
              file.name,
              file.data,
            ));
            this.store.dispatch(FileActions.UPLOAD_FILE_SUCCESS({
              file: {
                ...file,
                url: results.url,
                thumbnailUrl: results.thumbnailUrl
              }
            }));
          } catch (error: any) {
            if (
                error && (
                  error.status === 400 ||
                  error.status === 500
              )) {
              this.store.dispatch(FileActions.UPLOAD_FILE_ERROR({ file, error }));
              Sentry.metrics.increment("custom.UPLOAD_FAILURE", 1, {
                tags: {
                  path: file.path,
                  name: file.name,
                  error: error ? error.message : 'upload failed',
                  username: username,
                }
              });
              this.sentryErrorHandler.handleError(error);
            } else if (error && error.status === 401) {
              // consume permission error and upload after login
            } else {
              this.sentryErrorHandler.handleError(error);
            }
          }
        }
      }
    }
  }

  uploadPhoto(path: string, name: string, data?: string) {
    const reqBody = {
      path,
      name,
      data
    };
    return this.http.post<ImageInfo>(`${environment.API}/oss/media/photo`, reqBody);
  }

  getFile(key: string): Promise<ImageInfo | undefined> {
    return this.storageDriver.getFile(key);
  }

  removeFile(file: ImageInfo): Promise<void> {
    return this.storageDriver.removeFile(file);
  }

  async saveFile(file: ImageInfo, data: Blob): Promise<IndexableType> {
    let savedFile;
    const username = this.user?.username;
    try {
      savedFile = await this.storageDriver.saveFile(file, data);
    } catch (error: any) {
      this.store.dispatch(FileActions.UPLOAD_FILE_ERROR({ file, error }));
      Sentry.metrics.increment("custom.SAVE_FIL_FAILURE", 1, {
        tags: {
          path: file.path,
          name: file.name,
          error: error ? error.message : 'save failed',
          username: username,
        }
      });
      throw error;
    }
    return savedFile;
  }
}
