import { Store } from '@ngrx/store';

import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap, withLatestFrom, filter, tap, catchError} from 'rxjs/operators';
import {AppState} from "../app/app.state";
import {HttpErrorResponse} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {FileService} from "./file.service";
import {FileActions} from "./file.actions";
import {delay, draw} from "../../helpers";

@Injectable()
export class FileEffects {
  constructor(
    private actions$: Actions,
    public store: Store<AppState>,
    public service: FileService,
  ) {}

  createFile$ = createEffect(() => this.actions$.pipe(
    ofType(FileActions.CREATE_FILE),
    tap(async (action) => {
      await this.service.saveFile(action.file, action.data);
      await draw();
      this.service.processQueueEvent.next(undefined);
    }),
    switchMap((payload): Observable<any> => {
      return of();
    }),
  ));

  uploadFileSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(FileActions.UPLOAD_FILE_SUCCESS),
    tap(async (action) => {
      await this.service.removeFile(action.file);
    }),
    switchMap((payload): Observable<any> => {
      return of();
    }),
  ));
}
