import {Component, OnInit} from '@angular/core';
import {AuthService} from "./auth/auth.service";
import {ApiService} from "./api.service";
import {firstValueFrom} from "rxjs";
import {Router} from "@angular/router";
import {delay} from "./helpers";
import {Store} from "@ngrx/store";
import {isRehydrated} from "./+state/app/app.selectors";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import * as Sentry from "@sentry/angular-ivy";
import {SentryErrorHandler} from "./auth/sentry-error-handler";
import { Device } from '@capacitor/device';
import {MatSnackBar} from "@angular/material/snack-bar";


@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'photo-capture-app';

  isInitialized = false;

  constructor(
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private api: ApiService,
    private router: Router,
    private store: Store<any>,
    private sentryErrorHandler: SentryErrorHandler,
    ) { };

  async ngOnInit(): Promise<void> {
    this.store.select(isRehydrated).pipe(untilDestroyed(this)).subscribe(() => this.load());
    await this.checkIfNativeAndShowToast();
  }

  async load() {
    await delay(300); // give state time to hydrate before loading application
    this.isInitialized = true
    let token;
    try {
      await firstValueFrom(this.api.isAuthenticated());
    } catch(e: any) {
      if (e && e.status !== 401) {
        this.sentryErrorHandler.handleError(e);
      } else if (e && e.status === 401 && this.router.url !== '/login') {
        await this.router.navigateByUrl('/login');
      }
    }
  }

  async checkIfNativeAndShowToast() {
    const info = await Device.getInfo();
    if (info.platform !== 'web') {
      this.snackBar.open(
        `This application is in beta, do not use unless you are enrolled in the beta program.`,
        `\u00D7`,
        {
          duration: 5000,
          panelClass: ['app-notification-info']
        },
      );
    }
  }
}
