import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from "@angular/material/snack-bar";

import { ApiService } from '../api.service';
import {Store} from "@ngrx/store";
import {AppActions} from "../+state/app/app.actions";
import {AppState} from "../+state/app/app.state";
import * as Sentry from "@sentry/angular-ivy";
import {AuthService} from "./auth.service";
import {Device} from "@capacitor/device";
const packageInfo = require('../../../package.json');

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})

export class AuthComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({
      'username': new FormControl(null, [Validators.required]),
      'password': new FormControl(null, [Validators.required])
    });
  appVersion = packageInfo.version;
  submitting = false;
  isNative = false;

  isUpdatesEnabled = this.swUpdate.isEnabled;

  constructor(private api: ApiService,
    private router: Router,
    private store: Store<AppState>,
    private snackBar: MatSnackBar,
    private swUpdate: SwUpdate,
  ) {}

  async ngOnInit(): Promise<void> {
    const info = await Device.getInfo();
    this.isNative = (info.platform !== 'web');
  }


  onLogin() {
    if (this.loginForm.valid) {
      this.submitting = true;

      this.api.login(this.loginForm.value.username, this.loginForm.value.password)
      .subscribe({next: async (data: any) => {
          this.store.dispatch(AppActions.SET_USER({
            username: data.user['username'],
            token: data.token,
            firstName: data.user['firstName']
          }));

          Sentry.metrics.increment("custom.LOGIN", 1, {
            tags: {
              username: data.user['username'],
            }
          });

          this.router.navigate(['']);
          this.submitting = false;
      },
      error: (err) => {
        console.error('LOGIN ERROR');
        console.error('Error status:', err.status);
        console.error('Error message:', err.message);
        console.error('Error details:', err.error);
        console.error(err);
        this.submitting = false;
      }})
    } else {
      console.error('LOGIN FORM: invalid');
    };
  };
}
